import getEnv from './getEnv';

/**
 * Gets the HS Form Service API domain url for either production or staging
 * @returns {String} - api url for either production or staging
 */
export default function getFormServiceApiDomain() {
	let domain = 'https://hsapiservice.quinstage.com/';
	const env = getEnv();

	if (env === 'production') {
		domain = 'https://form-service-hs.qnst.com/';
	}

	return domain;
}
