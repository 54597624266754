/**
 * Runs a reload() on the page if query param of refreshExp
 * Used primarily to reset the cached widget consent experiences when clicking "back" after hitting the HRC
 */
export default function refreshCachedExperienceOnBack() {
	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('refreshExp')) {
		window.addEventListener('popstate', function() {
			window.location.reload();
		});
	}
}
