import { getModernizeDomain } from 'url';
/**
 * Get external json file hosted in quote repo
 * @param {Function} callback - callback
 * @returns {void}
 */
export default function getMotivationJSON(callback) {
	const motivationObj = JSON.parse(sessionStorage.getItem('motivationObj'));
	const req = new XMLHttpRequest();

	if (motivationObj) {
		return motivationObj;
	}

	req.open('GET', `${getModernizeDomain()}/quote/resources/data/motivation.json`);
	req.setRequestHeader('Content-Type', 'application/json');
	req.addEventListener('load', function() {
		if (this.status === 200 || this.status === 304) {
			const response = JSON.parse(this.responseText);
			sessionStorage.setItem('motivationObj', JSON.stringify(response.motivation));
			if ('function' === typeof callback) {
				callback(response.motivation);
			}
			return;
		}

		if ('function' === typeof callback) {
			callback({});
		}
	});
	req.send();
}
