/**
 * Sets cookie
 * @param {String} name - cookie's name
 * @param {String} value - cookie's value
 * @param {Integer} mins - cookie's lifetime in mins
 */
export default function setCookie(name, value, mins) {
	let expires = '';

	if (mins) {
		const date = new Date();
		date.setTime(date.getTime() + (mins * 60 * 1000));
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + value + expires + '; path=/';
}
