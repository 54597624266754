/**
 * vanilla JS solution for jQuery .slideDown()
 * @param {Object} target - element you want to slide
 * @param {Number} duration - time in ms to slide by
 */
export default function slideDown(target, duration = 500) {
	target.style.removeProperty('display');
	let display = window.getComputedStyle(target).display;
	const height = target.scrollHeight;
	if (display === 'none') {
		display = 'block';
	}
	target.style.display = display;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.boxSizing = 'border-box';
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = duration + 'ms';
	target.style.height = height + 'px';
	target.style.removeProperty('padding-top');
	target.style.removeProperty('padding-bottom');
	target.style.removeProperty('margin-top');
	target.style.removeProperty('margin-bottom');
	window.setTimeout(function() {
		target.style.removeProperty('height');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
}
