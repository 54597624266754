import getUrlParamsToArray from './getUrlParamsToArray';

/**
 * Get URL params into key/value object
 * @param {String} url - URL to get params (query string) of
 * @returns {Object} - object of URL params
 */
export default function getUrlParamsToObject(url) {
	const object = {};

	if (!url) {
		url = window.location.href.replace(window.location.hash, '');
	}

	const paramsArray = getUrlParamsToArray(url);
	for (const param of paramsArray) {
		object[param.name] = param.value;
	}

	return object;
}
