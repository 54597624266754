import getEnv from './getEnv';

/**
 * Gets the Modernize domain url for either production or staging.
 * @returns {String} - Modernize url for either production or staging
 */
export default function getModernizeDomain() {
	const domain = {
		local: 'http://modernize.local',
		staging: 'https://staging.modernize.com',
		qa: 'https://qa.modernize.com'
	};

	return domain[getEnv()] || 'https://modernize.com';
}
