/**
 * Function to Trap Focus in Dialog Modals
 * @param {Object} modalElement - the id of the modal dialog you want to trap focus in
 */
export default function trapTabFocusInDialog(modalElement) {
	const focusableElements = 'a[href], button, input:not(input[type=hidden]), textarea, select, details, [tabindex]:not([tabindex="-1"])';
	const modal = document.getElementById(modalElement);
	const modalTitle = modal.querySelector('.modal-title');

	let firstFocusableElement = modal.querySelector(focusableElements);
	const focusableContent = modal.querySelectorAll(focusableElements);
	const lastFocusableElement = focusableContent[focusableContent.length - 1];

	document.addEventListener('keydown', e => {
		const isTabPressed = e.key === 'Tab';

		if (!isTabPressed) {
			return;
		}

		// for contact us modal: update firstFocusableElement to be contained within the active step
		if (modalTitle && modalTitle.textContent.includes('Contact Us')) {
			const activeStep = modal.querySelector('.step--active');
			firstFocusableElement = activeStep.querySelector(focusableElements);
		}

		if (e.shiftKey) { // if shift key pressed for shift + tab combination (shift + tab focuses in reverse order)
			if (document.activeElement === firstFocusableElement) {
				lastFocusableElement.focus(); // add focus for the last focusable element
				e.preventDefault();
			}
		} else {
			if (document.activeElement === lastFocusableElement) { // if focused has reached last focusable element, then focus back on first focusable element after pressing tab
				firstFocusableElement.focus(); // add focus for the first focusable element
				e.preventDefault();
			}
		}
	});
}
