import getEnv from './getEnv';

/**
 * Gets the FormsWS domain url for either production or staging.
 * @returns {String} - api url for either production or staging
 */
export default function getFormsWSDomain() {
	let domain = window.location.protocol + '//formsws';
	const env = getEnv();

	if ('local' === env) {
		domain = 'http://formsws.local';
	} else if ('staging' === env || 'qa' === env) {
		domain += '.hilstaging.com';
	} else {
		domain += '.hilprod.com';
	}

	return domain;
}
