/**
 * Creates the checkboxes / wrappers for consent capture
 * @param {Object} vendors - response from the API
 * @param {Element} targetDiv - div to append the checkboxes to
 * @param {Boolean} formatVendors - if we need to format the list of vendors with ", " or " and "
 */
export default function renderConsentCheckboxes(vendors, targetDiv, formatVendors = false) {
	const modernizeTargetDiv = document.querySelector('[data-modernize-consent]');

	for (let i = 0; i < vendors.length; i++) {
		const vendorDisplayName = vendors[i].vendorDisplayName.trim();
		const [firstWordOfVendorName, ...restOfVendorName] = vendorDisplayName.split(' ');
		const remainingVendorNameText = restOfVendorName.join(' ');

		const span = document.createElement('span');
		span.classList.add('checkbox-wrapper');
		span.textContent = firstWordOfVendorName;

		const checkboxWrapper = document.createElement('span');
		checkboxWrapper.className = 'checkbox';

		const label = document.createElement('label');
		const input = document.createElement('input');
		input.className = 'consent-capture';
		input.type = 'checkbox';
		input.setAttribute('data-name',vendorDisplayName);
		input.setAttribute('data-key', vendors[i].vendorKey);
		input.setAttribute('checked', 'checked');

		label.textContent = remainingVendorNameText ? ` ${remainingVendorNameText}` : '';
		span.prepend(input);
		label.prepend(span);

		checkboxWrapper.appendChild(label);

		if (modernizeTargetDiv && vendorDisplayName === 'Modernize') {
			modernizeTargetDiv.innerHTML = checkboxWrapper.outerHTML;
		} else if (targetDiv) {
			targetDiv.appendChild(checkboxWrapper);
		}
	}

	if (formatVendors) {
		const nonModernizeVendors = targetDiv.querySelectorAll('.checkbox');
		if (nonModernizeVendors.length > 1) {
			for (let j = 0; j < nonModernizeVendors.length - 1; j++) { // looping through all vendors except the last one
				const label = nonModernizeVendors[j].querySelector('label');
				let additionalText = ', ';
				if (j === nonModernizeVendors.length - 2) { // adding "and" after the second to last one
					additionalText =  ', and ';
				}
				label.insertAdjacentHTML('beforeend', additionalText);
			}
		}
	}
}
