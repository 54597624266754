import { getMotivationJSON } from 'motivation';
import { setCookie } from 'cookie';

export default function saveMotivationParamToCookie(motivationValue) {
	if (motivationValue) {
		getMotivationJSON(motivationObj => {
			if (motivationObj) {
				const validValues = Object.keys(motivationObj);
				const cookieLifetimeInMin = 60 * 24; // 1 day

				if (validValues.indexOf(motivationValue) > -1) {
					setCookie('motivation', motivationValue, cookieLifetimeInMin);
				}
			}
		});
	}
}
