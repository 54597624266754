/**
 * Check current season
 * @returns {Boolean} - Is spring or summer?
 */
export default function isSpringOrSummer() {
	const today = new Date();
	const year = today.getFullYear();
	const firstDayOfSpring = new Date(`03-22-${year}`);
	const firstDayOfFall = new Date(`09-22-${year}`);

	return firstDayOfSpring < today && today < firstDayOfFall;
}
