import getCookie from './getCookie';
import setCookie from './setCookie';

export default function deleteFirstNameFromqsLeadData() {
	if (getCookie('qsLeadData') !== null) {
		const cookieData = JSON.parse(getCookie('qsLeadData'));
		if (cookieData.PROJECT && cookieData.PROJECT.FIRSTNAME) {
			delete cookieData.PROJECT.FIRSTNAME;
		}
		setCookie('qsLeadData', JSON.stringify(cookieData));
	}
}
