/**
 * FUTURE Deprecate -> Functionality exists in modalytics
 * Google Analytics Send Wrapper
 * Use It When You Need to Send Data to GA Loaded via GTM
 * @param {Object} data - data for Google Analytics send() method
 * @param {Object} gaTracker - ID of a Google Analytics tracker (NOT GA-***)
 */
export default function gaSend(data, gaTracker) {
	if (window.Modalytics) {
		// New GA
		if ('undefined' !== typeof ga) {
			ga((gaTracker ? gaTracker + '.' : '') + 'send', data); // eslint-disable-line no-undef
		} else if ('undefined' !== typeof _gaq) { // Old GA
			// Pageview
			if (data.hitType === 'pageview' && data.page) {
				_gaq.push([(gaTracker ? gaTracker + '.' : '') + '_trackPageview', data.page]); // eslint-disable-line no-undef
			}

			// Event
			if (data.hitType === 'event' && data.eventCategory) {
				const eventConfig = [(gaTracker ? gaTracker + '.' : '') + '_trackEvent'],
					eventProperties = ['eventCategory', 'eventAction', 'eventLabel'];

				for (let i = 0; i < eventProperties.length; i ++) {
					if (data[eventProperties[i]]) {
						eventConfig.push(data[eventProperties[i]]);
					}
				}

				_gaq.push(eventConfig); // eslint-disable-line no-undef
			}
		}
	}
}
