/**
 * Encodes the value of a key in an object
 * @param {Object} object - It is the data object that has a value which needs to be encoded
 * @param {String} key - Key of the value that needs to be encoded
 * @returns {Object} - returning he updated object
 */
export default function encodeValueInObject(object, key) {
	if (object[key]) {
		object[key] = encodeURIComponent(object[key]);
	}

	return object;
}