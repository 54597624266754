/**
 * Debounce wrapper
 * @param {Function} func - function you want to debounce
 * @param {Number} wait - time in ms to debounce by
 * @returns {Function} - debounce wrapped function
 */
export default function debounce(func, wait) {
	let timeout;
	let args;
	let eventFiredLast;

	function later() {
		const timeElapsed = new Date().getTime() - eventFiredLast;
		if (timeElapsed < wait && timeElapsed >= 0) {
			timeout = setTimeout(later, wait - timeElapsed);
		} else {
			timeout = null;
			func(...args);
		}
	}

	return function() {
		args = arguments;
		eventFiredLast = new Date().getTime();
		if (!timeout) {
			timeout = setTimeout(later, wait);
		}
	};
}
