/**
 * Initializes smooth scrolling for all anchors on a page
 */
export default function initSmoothScrolling() {
	document.querySelectorAll('a[href^="#"]').forEach(trigger => {
		trigger.onclick = function(e) {
			e.preventDefault();
			const hash = this.getAttribute('href');
			const target = document.querySelector(hash),
				elementPosition = target.offsetTop,
				offsetPosition = elementPosition;
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			});
		};
	});
}
