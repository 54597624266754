import { IdleQueue } from 'idlize/IdleQueue.mjs';

/**
 * Idlize wrapper
 * @param {Function} func - function you want to debounce
 */
function idlize() {
	setTimeout(() => {
		const queue = new IdleQueue({ensureTasksRun: true});
		queue.pushTask(() => {
			if (typeof dataLayer !== 'undefined') {
				window.dataLayer.push({
					event: 'lateLoadTags'
				});
				window.lateLoaded = true;
			}
		});
	}, 1000);
	window.lateLoaded = false;
	window.IdlizeQueue = new IdleQueue();
}

// Auto initialize idlize
idlize();
