import { getSupportedTrackingParams, getUrlParamsToArray } from 'url';

export default function cleanUrlQueryParamString() {
	const supportedTrackingParams = getSupportedTrackingParams();
	let params = getUrlParamsToArray();
	let queryParamString;

	params = params.filter(function(param) {
		return supportedTrackingParams.indexOf(param.name.toLowerCase()) === -1;
	});

	queryParamString = params.length ? '?' : '';

	params.forEach(function(param, index) {
		queryParamString += encodeURIComponent(param.name) + '=' + encodeURIComponent(param.value);

		if (index !== (params.length - 1)) {
			queryParamString += '&';
		}
	});

	return queryParamString;
}
