import getEnv from './getEnv';

/**
 * Gets HIL Forms Webservices domain
 * @returns {String} - api url for either production or staging
 */
export default function getHILFormsWSDomain() {
	let formsWsDomain = '';
	const env = getEnv();

	if ('production' === env) {
		formsWsDomain = 'https://homeimprovementleads.com';
	} else if ('staging' === env || 'qa' === env) {
		formsWsDomain = 'https://staging.homeimprovementleads.com';
	} else {
		formsWsDomain = 'http://hil.local';
	}

	return formsWsDomain + '/ws/';
}
