/**
 * Get URL params into array
 * @param {String} url - URL to get params (query string) of
 * @returns {Array} - array of URL params
 */
export default function getUrlParamsToArray(url) {
	const request = [],
		urlKeysInUse = [];

	if (!url) {
		url = window.location.href.replace(window.location.hash, '');
	}

	if (url.indexOf('?') > -1) {
		const pairs = url.substring(url.indexOf('?') + 1).split('&');

		for (let i = 0; i < pairs.length; i ++) {
			if (!pairs[i]) {
				continue;
			}

			const pair = pairs[i].split(/=(.+)/),
				item = {
					name: decodeURIComponent(pair[0]),
					value: 'undefined' !== typeof pair[1] ? decodeURIComponent(pair[1].replace(/\+/g, '%20')) : null
				};

			// make sure no dupes
			if (urlKeysInUse.indexOf(pair[0]) === -1) {
				urlKeysInUse.push(pair[0]);
				request.push(item);
			}
		}
	}

	return request;
}
