import getFormServiceApiDomain from '../url/getFormServiceApiDomain';

/**
 * Hits the API to get the branded display name used for consent capture
 * @param {String} vendorPublicIdentifier - can be found in QMP > Client Details (for consent purposes, should only be displaying / accepting one client value)
 */
export default function renderBrandedConsent(vendorPublicIdentifier) {
	const brandedTargetDiv = document.querySelector('[data-branded-consent]'),
		apiDomain = getFormServiceApiDomain(),
		apiUrl = `${apiDomain}utils/vendor-display-name?vendorKeys=${vendorPublicIdentifier}`,
		request = new XMLHttpRequest();

	let response = {};
	request.open('GET', apiUrl);
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200 && brandedTargetDiv) {
				brandedTargetDiv.innerHTML = response.data[vendorPublicIdentifier];
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');
	});

}
