import getUrlParamsToObject from './getUrlParamsToObject';

/**
 * Get URL params into key/value object with keys in lowercase
 * @param {String} url - URL to get params (query string) of
 * @returns {Object} - object of URL params
 */
export default function getUrlParamsToObjectLC(url) {
	if (!url) {
		url = window.location.href.replace(window.location.hash, '');
	}

	const params = getUrlParamsToObject(url),
		paramsLC = {};

	for (const name in params) {
		if (Object.prototype.hasOwnProperty.call(params, name)) {
			const value = params[name];
			paramsLC[name.toLowerCase()] = value;
		}
	}

	return paramsLC;
}
