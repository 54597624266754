/**
 * Function to update hero title & subtitle. Used in QS White Label sites.
 * @param {Object} headerData - headerData object
 * @param {Integer} step - active step index
 * @returns {void}
 */
export default function customHeader(headerData, step) {
	const getVars = window.modForm.opts.getVars;
	const hasDynamicTitleOrSubtitle = getVars && (getVars.title || getVars.subtitle);
	const heroTitle = document.querySelector('.hero__title');
	const heroSubTitle = document.querySelector('.hero__subtitle');
	const fallbackDataStepName = 'other';
	const zipStepName = 'zip';
	const stepName = window.modForm.opts.form.querySelector('.step--active').getAttribute('data-step-name');

	// if headerData object keys are associated with data step name then use step name instead of index. For example: americanstandard
	if (Object.keys(headerData).includes(stepName)) {
		step = stepName;
	} else if (headerData[fallbackDataStepName]) {
		step = fallbackDataStepName;
	}

	// condition to update dynamic hero title & subtitle only on first step
	if (hasDynamicTitleOrSubtitle && (step === 1 || step === zipStepName)) {
		window.modForm.initFormDynamicContent();
		return false;
	}

	if (headerData && headerData[step]) {
		heroTitle.innerHTML = headerData[step].headline;
		if (heroTitle.querySelector('.user-location')) {
			heroTitle.querySelector('.user-location').innerHTML = window.modForm.opts.userLocation;
		}
		if (headerData[step].p) {
			heroSubTitle.innerHTML = headerData[step].p;
		}
	}
}
