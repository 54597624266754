import getEnv from './getEnv';

/**
 * Gets the API domain url for either production or staging.
 * @returns {String} - api url for either production or staging
 */
export default function getApiDomain() {
	const domain = {
		local: 'https://api.hilstaging.com',
		staging: 'https://api.hilstaging.com',
		qa: 'https://api.hilstaging.com'
	};

	return domain[getEnv()] || 'https://api.modernize.com';
}
