/**
 * Fetch Script Function
 * @param {Source} source - URL of the script you want to fetch
 * @param {Callback} callback - fired once the script has loaded
 */
export default function fetchScript(source, callback) {
	const script = document.createElement('script');
	script.onload = callback;
	script.src = source;
	document.body.appendChild(script);
}
